import { Controller } from '@hotwired/stimulus'

function dotClickHandler (event) {
  if (event.target.classList.contains(this.dotClass)) {
    const dotIndex = Array.from(event.target.parentNode.childNodes).indexOf(event.target)
    const scrollStep = this.findScrollStep()
    const scrollLeft = (Math.ceil(this.scrollableTarget.offsetWidth / scrollStep) - 1) * scrollStep * dotIndex

    this.scrollableTarget.scrollLeft = scrollLeft
  }
}

export default class extends Controller {
  static targets = [
    'scrollable',
    'list',
    'prev',
    'next',
    'dots'
  ]

  static classes = [
    'dot',
    'activeDot',
    'disabled'
  ]

  static values = {
    offsetLeft: Number
  }

  initialize () {
    this.boundDotClick = dotClickHandler.bind(this)
  }

  connect () {
    this.updateControls()

    this.resizeObserver = new ResizeObserver(() => {
      this.updateControls()
    })

    this.resizeObserver.observe(this.scrollableTarget)
  }

  disconnect () {
    this.resizeObserver.disconnect()
  }

  dotsTargetConnected (el) {
    el.addEventListener('click', this.boundDotClick)
  }

  dotsTargetDisconnected (el) {
    el.removeEventListener('click', this.boundDotClick)
  }

  scrollLeft () {
    const scrollStep = this.findScrollStep()
    if (scrollStep == null) return

    const currentPage = Math.ceil(Math.floor(this.scrollableTarget.scrollLeft + this.offsetLeftValue) / scrollStep)
    const scrollLeft = (currentPage - 1) * scrollStep

    this.scrollableTarget.scrollLeft = scrollLeft - this.offsetLeftValue
  }

  scrollRight () {
    const scrollStep = this.findScrollStep()
    if (scrollStep == null) return

    const currentPage = Math.floor(Math.ceil(this.scrollableTarget.scrollLeft + this.offsetLeftValue) / scrollStep)
    const scrollLeft = (currentPage + 1) * scrollStep

    this.scrollableTarget.scrollLeft = scrollLeft - this.offsetLeftValue
  }

  scrollToLeft () {
    this.scrollableTarget.scrollLeft = 0
  }

  scrollToRight () {
    const { offsetWidth, scrollWidth } = this.scrollableTarget
    this.scrollableTarget.scrollLeft = scrollWidth - offsetWidth
  }

  findScrollStep () {
    const listItems = this.listTarget.childNodes

    if (listItems.length < 2) return null

    const startOffset = listItems[0].offsetLeft
    const width = listItems[1].offsetLeft - startOffset
    const count = Math.max(Math.floor((this.scrollableTarget.offsetWidth - startOffset) / width), 1)

    return width * count
  }

  updateButtons () {
    const { scrollLeft, offsetWidth, scrollWidth } = this.scrollableTarget
    this.prevTarget.classList.toggle(this.disabledClass, scrollLeft === 0)
    this.nextTarget.classList.toggle(this.disabledClass, offsetWidth + scrollLeft >= scrollWidth)
  }

  updateDots () {
    if (!this.hasDotsTarget) return

    const { offsetWidth, scrollWidth } = this.scrollableTarget
    const dotsCount = this.dotsTarget.childNodes.length
    let pages = Math.ceil(scrollWidth / offsetWidth)

    if (pages === 1) pages = 0

    if (dotsCount !== pages) {
      if (dotsCount < pages) {
        for (let i = 0; i < pages - dotsCount; i++) {
          const dot = document.createElement('div')
          dot.classList.add(this.dotClass)
          this.dotsTarget.appendChild(dot)
        }
      } else {
        for (let i = 0; i < dotsCount - pages; i++) {
          this.dotsTarget.removeChild(this.dotsTarget.firstChild)
        }
      }

      this.activateDot()
    }
  }

  activateDot () {
    if (!this.hasDotsTarget) return

    let activeDot = this.dotsTarget.querySelector(`.${this.dotClass}.${this.activeDotClass}`)

    if (activeDot) {
      activeDot.classList.remove(this.activeDotClass)
    }

    const { offsetWidth, scrollWidth, scrollLeft } = this.scrollableTarget
    let currentPage = 1

    if (scrollLeft) {
      const pages = Math.ceil(scrollWidth / offsetWidth)

      if (offsetWidth + scrollLeft === scrollWidth) {
        currentPage = pages
      } else {
        currentPage = Math.floor(scrollLeft / this.findScrollStep()) + 1
      }
    }

    activeDot = this.dotsTarget.querySelector(`.${this.dotClass}:nth-child(${currentPage})`)

    if (activeDot) {
      activeDot.classList.add(this.activeDotClass)
    }
  }

  updateControls () {
    this.updateButtons()
    this.updateDots()
  }
}
