/* globals evil */

/*
  <button type="button" data-controller="popover"
                        data-action="popover#toggle"
                        data-popover-options-value="{\"offset\": [0, 0]}">
    Button title
    <template data-popover-target="template">
      <div class="popover">
        <div class="popover-header">
          Popover header
        </div>
        <div class="popover-content">
          Popover content
        </div>
      </div>
    </template>
  </button>
*/

import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'

export default class extends Controller {
  static targets = ['template', 'reference']
  static values = {
    options: Object
  }

  initialize () {
    this.boundCloseOnClickOutside = this.closeOnClickOutside.bind(this)
  }

  disconnect () {
    this.close()
  }

  toggle () {
    if (this.popper) {
      this.close()
    } else {
      this.open()
    }
  }

  open () {
    let anchorElement = document.body

    this.popoverElement = this.templateTarget.content.cloneNode(true).firstChild

    if (this.element.dataset.popoverAppend) {
      if (this.element.dataset.popoverAppend === 'element') {
        anchorElement = this.element
      } else {
        const el = document.querySelector(this.element.dataset.popoverAppend)
        if (el) {
          anchorElement = el
        }
      }
    }

    anchorElement.appendChild(this.popoverElement)

    setTimeout(() => {
      document.addEventListener('click', this.boundCloseOnClickOutside)
      evil.block.vitalize(this.popoverElement)
    }, 0)

    this.popoverElement.classList.add('is-visible')
    this.element.classList.add('is-active')

    const reference = this.hasReferenceTarget ? this.referenceTarget : this.element

    this.popper = createPopper(reference, this.popoverElement, {
      placement: 'bottom-start',
      strategy: this.element.dataset.popoverStrategy || 'fixed'
    })
    this.updatePopperOptions()
  }

  close () {
    this.element.classList.remove('is-active')

    document.removeEventListener('click', this.boundCloseOnClickOutside)

    if (this.popper) {
      this.popper.destroy()
      this.popper = null
      this.popoverElement.parentNode.removeChild(this.popoverElement)
      this.popoverElement = null
    }
  }

  closeNext () {
    setTimeout(this.close.bind(this), 0)
  }

  optionsValueChanged () {
    this.updatePopperOptions()
  }

  updatePopperOptions () {
    if (!this.popper) return

    const { offset } = this.optionsValue

    this.popper.setOptions({
      placement: this.optionsValue.placement || 'bottom-start',
      modifiers: [{
        name: 'arrow'
      }, {
        name: 'flip',
        options: {
          fallbackPlacements: [
            'bottom-start',
            'bottom-end',
            'top-start',
            'top-end'
          ]
        }
      }, {
        name: 'offset',
        options: {
          offset: ({ placement }) => {
            if (offset) {
              const skidding = placement.split('-')[1] === 'end' ? offset[0] * -1 : offset[0]
              const distance = offset[1]

              return [skidding, distance]
            } else {
              return []
            }
          }
        }
      }]
    })
  }

  closeOnClickOutside (event) {
    if (event.target.isConnected && !this.popoverElement.contains(event.target)) {
      this.close()
    }
  }
}
