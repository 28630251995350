import { Controller } from '@hotwired/stimulus'

const SUBMIT_EVENTS = ['ajax:complete', 'turbo:submit-end']
const LINK_EVENTS = ['ajax:complete']

export default class extends Controller {
  initialize () {
    this.boundActivate = this.activate.bind(this)
    this.boundDeactivate = this.deactivate.bind(this)
  }

  connect () {
    if (this.isSubmitButton) {
      for (const eventName of SUBMIT_EVENTS) {
        this.element.form.addEventListener(eventName, this.boundDeactivate)
      }
    } else {
      for (const eventName of LINK_EVENTS) {
        this.element.addEventListener(eventName, this.boundDeactivate)
      }
    }

    this.element.addEventListener('click', this.boundActivate)
  }

  disconnect () {
    if (this.isSubmitButton) {
      for (const eventName of SUBMIT_EVENTS) {
        this.element.form.removeEventListener(eventName, this.boundDeactivate)
      }
    } else {
      for (const eventName of LINK_EVENTS) {
        this.element.removeEventListener(eventName, this.boundDeactivate)
      }
    }

    this.element.removeEventListener('click', this.boundActivate)
  }

  activate () {
    this.element.classList.add('with-loader')
    if (this.isSubmitButton) {
      setTimeout(() => (this.element.disabled = true), 0)
    }
  }

  deactivate () {
    this.element.classList.remove('with-loader')
    if (this.isSubmitButton) {
      setTimeout(() => (this.element.disabled = false), 0)
    }
  }

  get isSubmitButton () {
    return this.element.tagName === 'BUTTON' && this.element.type === 'submit'
  }
}
