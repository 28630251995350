import { Controller } from '@hotwired/stimulus'

const LOADING_CLASS = 'is-loading'
const HIDE_CLASS = 'is-hidden'
const OPTION_TITLE_SELECTOR = '[data-option-title]'

function nextOperation ({ current, ops }) {
  const index = ops.findIndex((v) => v === current)

  if (index === -1) {
    return ops[0]
  } else if (index === ops.length - 1) {
    return null
  } else {
    return ops[index + 1]
  }
}

export default class extends Controller {
  static targets = [
    'optionTemplate',
    'addInput',
    'removeInput',
    'availableContainer',
    'availableOption',
    'selectedContainer',
    'selectedOption',
    'paginationMark',
    'addOption'
  ]

  static values = {
    url: String,
    entirelySelected: Array,
    partlySelected: Array,
    selectedOptions: Object
  }

  initialize () {
    this.query = ''
    this.paginationMarkObserver = new IntersectionObserver(this.paginationMarkObserverCallback.bind(this))
    this.searchThrottleTimeout = null
    this.selectedValueMap = new Map()
    this.operations = new Map()
  }

  connect () {
    if (this.hasSelectedOptionsValue) {
      this.resetSelectedOptions(this.selectedOptionsValue)
    }
  }

  disconnect () {
    this.paginationMarkObserver.disconnect()
    this.currentRequest?.abort()

    clearTimeout(this.searchThrottleTimeout)
  }

  paginationMarkTargetConnected (el) {
    this.paginationMarkObserver.observe(el)
  }

  paginationMarkTargetDisconnected (el) {
    this.paginationMarkObserver.unobserve(el)
  }

  paginationMarkObserverCallback (entries) {
    if (!entries[0].isIntersecting) return

    this.performPageQuery()
  }

  availableOptionTargetConnected (el) {
    const value = el.getAttribute(this.data.getAttributeNameForKey('valueParam'))
    el.classList.toggle(HIDE_CLASS, this.selectedValueMap.has(value))
  }

  entirelySelectedValueChanged (value) {
    this.entirelySelectedSet = new Set(value)
  }

  partlySelectedValueChanged (value) {
    this.partlySelectedSet = new Set(value)
  }

  insertSelectedOption (value, text) {
    const selectedOption = this.optionTemplateTarget.content.firstChild.cloneNode(true)
    selectedOption.setAttribute(this.data.getAttributeNameForKey('valueParam'), value)
    selectedOption.classList.add(this.partlySelectedSet.has(value) ? 'is-mixed' : 'is-all')

    const titleEl = selectedOption.querySelector(OPTION_TITLE_SELECTOR) || selectedOption
    titleEl.textContent = text

    const insertBeforeNode = this.selectedOptionTargets.find((el) => el.textContent.localeCompare(text) >= 0)

    if (insertBeforeNode == null) {
      this.selectedContainerTarget.appendChild(selectedOption)
    } else {
      this.selectedContainerTarget.insertBefore(selectedOption, insertBeforeNode)
    }

    this.findOption(value, this.availableOptionTargets)?.classList.add(HIDE_CLASS)
  }

  removeSelectedOption (value) {
    this.findOption(value, this.availableOptionTargets)?.classList.remove(HIDE_CLASS)
    this.findOption(value, this.selectedOptionTargets)?.remove()

    this.selectedValueMap.delete(value)
  }

  add () {
    const value = this.query.toLowerCase()

    this.addOptionTarget.classList.add(HIDE_CLASS)
    this.insertSelectedOption(value, this.query)
    this.selectedValueMap.set(value, this.query)
    this.operations.set(value, { current: 'add', ops: ['add'] })
    this.addHiddenInput(value, this.addInputTarget)
  }

  toggle ({ currentTarget, params: { value } }) {
    const isSelected = this.selectedValueMap.has(value)
    let op = this.operations.get(value)
    const text = (currentTarget.querySelector(OPTION_TITLE_SELECTOR) || currentTarget)?.textContent

    if (op == null) { // Available -> Add
      op = { current: null, ops: ['add'] }
      this.operations.set(value, op)
    }

    op.current = nextOperation(op)

    if (op.current === 'add') {
      if (isSelected) { // Partly Selected -> Add
        const option = this.findOption(value, this.selectedOptionTargets)
        option.classList.remove('is-mixed')
        option.classList.add('is-all')
      } else { // Available -> Add
        this.insertSelectedOption(value, text)
        this.selectedValueMap.set(value, text)
      }

      this.addHiddenInput(value, this.addInputTarget)
      this.removeHiddenInput(value, this.removeInputTargets)
    } else if (op.current === 'remove') {
      // Entirely Selected -> Remove, Partly Selected (Add) -> Remove
      this.removeSelectedOption(value)
      this.addHiddenInput(value, this.removeInputTarget)
      this.removeHiddenInput(value, this.addInputTargets)
      this.selectedValueMap.delete(value)
    } else {
      if (isSelected) { // Add -> Available, Created -> Null
        this.removeSelectedOption(value)
        this.selectedValueMap.delete(value)
      } else { // Remove -> Selected (Partly or Entirely)
        this.insertSelectedOption(value, text)
        this.selectedValueMap.set(value, text)
      }

      this.removeHiddenInput(value, this.addInputTargets)
      this.removeHiddenInput(value, this.removeInputTargets)
    }
  }

  resetSelectedOptions (options) {
    const sortedOptions = Object.entries(options).sort((a, b) => a[1].localeCompare(b[1]))

    this.selectedValueMap = new Map(sortedOptions)
    this.selectedOptionTargets.forEach((el) => el.remove())
    this.operations = new Map()

    for (const [value, text] of sortedOptions) {
      const selectedOption = this.optionTemplateTarget.content.firstChild.cloneNode(true)
      const titleEl = selectedOption.querySelector(OPTION_TITLE_SELECTOR) || selectedOption

      selectedOption.setAttribute(this.data.getAttributeNameForKey('valueParam'), value)
      titleEl.textContent = text

      const isEntirelySelected = this.entirelySelectedSet.has(value)
      const isPartlySelected = this.partlySelectedSet.has(value)

      this.operations.set(value, { current: null, ops: isPartlySelected ? ['add', 'remove'] : ['remove'] })

      selectedOption.classList.toggle('is-all', isEntirelySelected)
      selectedOption.classList.toggle('is-mixed', isPartlySelected)

      this.selectedContainerTarget.appendChild(selectedOption)
    }
  }

  search (event) {
    clearTimeout(this.searchThrottleTimeout)

    if (this.hasAddOptionTarget) {
      this.addOptionTarget.classList.add(HIDE_CLASS)
    }

    const query = event.target.value.trim()
    const token = query.toLowerCase()
    this.query = query

    let hasExactMatch = this.searchOptions(this.selectedOptionTargets, token)

    this.availableContainerTarget.innerHTML = ''
    this.element.classList.add(LOADING_CLASS)

    const throttledSearch = () => {
      this.performSearchQuery().complete(() => {
        hasExactMatch ||= this.searchOptions(this.availableOptionTargets, token)

        if (this.hasAddOptionTarget) {
          this.addOptionTarget.classList.toggle(HIDE_CLASS, hasExactMatch)
          const optionTitleEl = this.addOptionTarget.querySelector(OPTION_TITLE_SELECTOR) || this.addOptionTarget
          optionTitleEl.textContent = query
        }
      })
    }

    this.searchThrottleTimeout = setTimeout(throttledSearch, 200)
  }

  searchOptions (options, token) {
    if (token === '') {
      options.forEach((el) => el.classList.remove(HIDE_CLASS))
      return true
    }

    let hasExactMatch = false

    options.forEach((el) => {
      const text = el.textContent.trim().toLowerCase()

      if (text.includes(token)) {
        if (text === token) {
          hasExactMatch = true
        }

        el.classList.remove(HIDE_CLASS)
      } else {
        el.classList.add(HIDE_CLASS)
      }
    })

    return hasExactMatch
  }

  addHiddenInput (value, target) {
    const valueInput = target.cloneNode()
    valueInput.value = value
    valueInput.disabled = false
    target.after(valueInput)
  }

  removeHiddenInput (value, targets) {
    targets.find((el) => el.value === value)?.remove()
  }

  findOption (value, targets) {
    value = value.toString()
    const attributeName = this.data.getAttributeNameForKey('valueParam')
    return targets.find((el) => el.getAttribute(attributeName) === value)
  }

  performSearchQuery () {
    this.currentRequest?.abort()

    const { query } = this

    return this.performQuery({ query }).success((responseText) => {
      $(this.availableContainerTarget).html(responseText)
    })
  }

  performPageQuery () {
    const { query } = this
    const { page } = this.paginationMarkTarget.dataset

    return this.performQuery({ query, page }).success((responseText) => {
      $(this.paginationMarkTarget).replaceWith(responseText)
    })
  }

  performQuery ({ query, page }) {
    return $.rails.ajax({
      url: this.urlValue,
      data: { query, page: page || 1 },
      dataType: 'html',
      beforeSend: (xhr) => {
        if (this.currentRequest) {
          return false
        }

        this.currentRequest = xhr
        this.element.classList.add(LOADING_CLASS)
      },
      complete: () => {
        this.currentRequest = null
        this.element.classList.remove(LOADING_CLASS)
      }
    })
  }
}
