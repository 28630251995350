
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selected']
  static outlets = ['remote-options-search']
  static values = {
    selected: Object,
    inputName: String
  }

  initialize () {
    this.hiddenInputs = []
  }

  selectedValueChanged (selectedValues) {
    const values = Object.keys(selectedValues)

    if (values.length > 0) {
      this.selectedTarget.textContent = values.join(', ')
    } else {
      this.selectedTarget.innerHTML = '&nbsp;'
    }

    this.hiddenInputs.forEach((hidden) => hidden.remove())

    this.hiddenInputs = values.map((value) => {
      const hidden = document.createElement('input')
      hidden.type = 'hidden'
      hidden.name = this.inputNameValue
      hidden.value = value

      this.element.appendChild(hidden)

      return hidden
    })
  }

  remoteOptionsSearchOutletConnected (outlet, el) {
    outlet.resetSelectedOptions(this.selectedValue)
    el.addEventListener('remote-options-search:change', ({ detail: { value } }) => {
      this.selectedValue = value
    })
  }
}
