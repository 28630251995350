import { Controller } from '@hotwired/stimulus'

/*
  <div data-controller="remote-options-search"
       data-remote-options-search-url-value="/options">

    <!-- First hidden input is like template for other values. Disable it to if you don't need empty values in form -->
    <input type="hidden" name="values[]" value="" data-remote-options-search-target="valueInput" disabled>
    <input type="hidden" name="values[]" value="selected" data-remote-options-search-target="valueInput">

    <input type="search" data-action='remote-options-search#search'>

    <button type="button" class="is-hidden" data-remote-options-search-target="addOption"
                                            data-action="remote-options-search#add">
      Add <span data-option-title=""></span>
    </button>

    <!-- Template for selected option -->
    <template data-remote-options-search-target="optionTemplate">
      <button type="button" data-remote-options-search-target="selectedOption"
                            data-remote-options-search-value-param=""
                            data-action="remote-options-search#deselect">
        <!-- Mark element where title should go (optional) -->
        <span data-option-title=""></span>
      </button>
    </template>

    <div data-remote-options-search-target="selectedContainer">
      <button type="button" data-remote-options-search-target="selectedOption"
                            data-remote-options-search-value-param="selected"
                            data-action="remote-options-search#deselect">
        <!-- Mark element from which title can be extracted (optional) -->
        <span data-option-title="">Selected Option</span>
      </button>
    </div>
    <div data-remote-options-search-target="availableContainer">
      <button class="is-hidden" data-remote-options-search-target="availableOption">
                                data-remote-options-search-value-param="hello world"
                                data-action="remote-options-search#select"
        <span options-item-title data-option-title="">Hello, World!</span>
      </button>

      <span data-remote-options-search-target="paginationMark" data-page="2"></span>
    </div>
  </div>
*/

const LOADING_CLASS = 'is-loading'
const HIDE_CLASS = 'is-hidden'
const OPTION_TITLE_SELECTOR = '[data-option-title]'

export default class extends Controller {
  static targets = [
    'optionTemplate',
    'valueInput',
    'availableContainer',
    'availableOption',
    'selectedContainer',
    'selectedOption',
    'paginationMark',
    'addOption'
  ]

  static values = {
    url: String,
    selectedOptions: Object
  }

  initialize () {
    this.query = ''
    this.paginationMarkObserver = new IntersectionObserver(this.paginationMarkObserverCallback.bind(this))
    this.searchThrottleTimeout = null
    this.selectedValueMap = new Map()
  }

  connect () {
    if (this.hasSelectedOptionsValue) {
      this.resetSelectedOptions(this.selectedOptionsValue)
    }
  }

  disconnect () {
    this.paginationMarkObserver.disconnect()
    this.currentRequest?.abort()

    clearTimeout(this.searchThrottleTimeout)
  }

  paginationMarkTargetConnected (el) {
    this.paginationMarkObserver.observe(el)
  }

  paginationMarkTargetDisconnected (el) {
    this.paginationMarkObserver.unobserve(el)
  }

  paginationMarkObserverCallback (entries) {
    if (!entries[0].isIntersecting) return

    this.performPageQuery()
  }

  availableOptionTargetConnected (el) {
    const value = el.getAttribute(this.data.getAttributeNameForKey('valueParam'))
    el.classList.toggle(HIDE_CLASS, this.selectedValueMap.has(value))
  }

  select ({ currentTarget, params: { value } }) {
    const text = (currentTarget.querySelector(OPTION_TITLE_SELECTOR) || currentTarget)?.textContent
    this.insertSelectedOption(value, text)
    currentTarget.classList.add(HIDE_CLASS)
    this.selectedValueMap.set(value, text)
    this.notifyChanged()
  }

  add ({ currentTarget }) {
    this.insertSelectedOption(this.query, this.query)
    currentTarget.classList.add(HIDE_CLASS)
    this.selectedValueMap.set(this.query, this.query)
    this.notifyChanged()
  }

  insertSelectedOption (value, text) {
    const selectedOption = this.optionTemplateTarget.content.firstChild.cloneNode(true)
    selectedOption.setAttribute(this.data.getAttributeNameForKey('valueParam'), value)

    const titleEl = selectedOption.querySelector(OPTION_TITLE_SELECTOR) || selectedOption
    titleEl.textContent = text

    const insertBeforeNode = this.selectedOptionTargets.find((el) => el.textContent > text)

    if (insertBeforeNode) {
      this.selectedContainerTarget.insertBefore(selectedOption, insertBeforeNode)
    } else {
      this.selectedContainerTarget.appendChild(selectedOption)
    }

    this.insertHiddenInput(value)
  }

  deselect ({ currentTarget, params: { value } }) {
    value = value.toString()

    const availableOption = this.availableOptionTargets.find((el) => {
      return el.getAttribute(this.data.getAttributeNameForKey('valueParam')) === value
    })

    availableOption?.classList.remove(HIDE_CLASS)
    currentTarget.remove()
    this.removeHiddenInput(value)

    this.selectedValueMap.delete(value)
    this.notifyChanged()
  }

  notifyChanged () {
    const value = Object.fromEntries(this.selectedValueMap)
    const count = this.selectedValueMap.size
    this.dispatch('change', { detail: { value, count } })
  }

  search (event) {
    clearTimeout(this.searchThrottleTimeout)

    if (this.hasAddOptionTarget) {
      this.addOptionTarget.classList.add(HIDE_CLASS)
    }

    const query = event.target.value.trim()
    const token = query.toLowerCase()
    this.query = query

    let hasExactMatch = this.searchOptions(this.selectedOptionTargets, token)

    this.availableContainerTarget.innerHTML = ''
    this.element.classList.add(LOADING_CLASS)

    const throttledSearch = () => {
      this.performSearchQuery().complete(() => {
        hasExactMatch ||= this.searchOptions(this.availableOptionTargets, token)

        if (this.hasAddOptionTarget) {
          this.addOptionTarget.classList.toggle(HIDE_CLASS, hasExactMatch)
          const optionTitleEl = this.addOptionTarget.querySelector(OPTION_TITLE_SELECTOR) || this.addOptionTarget
          optionTitleEl.textContent = query
        }
      })
    }

    this.searchThrottleTimeout = setTimeout(throttledSearch, 200)
  }

  searchOptions (options, token) {
    if (token === '') {
      options.forEach((el) => el.classList.remove(HIDE_CLASS))
      return true
    }

    let hasExactMatch = false

    options.forEach((el) => {
      const text = el.textContent.trim().toLowerCase()

      if (text.includes(token)) {
        if (text === token) {
          hasExactMatch = true
        }

        el.classList.remove(HIDE_CLASS)
      } else {
        el.classList.add(HIDE_CLASS)
      }
    })

    return hasExactMatch
  }

  resetSelectedOptions (options) {
    const sortedOptions = Object.entries(options).sort((a, b) => a[1].localeCompare(b[1]))

    this.selectedValueMap = new Map(sortedOptions)
    this.selectedOptionTargets.forEach((el) => el.remove())
    this.valueInputTargets.slice(1).forEach((el) => el.remove())

    for (const [value, text] of sortedOptions) {
      const selectedOption = this.optionTemplateTarget.content.firstChild.cloneNode(true)
      const titleEl = selectedOption.querySelector(OPTION_TITLE_SELECTOR) || selectedOption

      selectedOption.setAttribute(this.data.getAttributeNameForKey('valueParam'), value)
      titleEl.textContent = text

      this.selectedContainerTarget.appendChild(selectedOption)
      this.insertHiddenInput(value)
    }
  }

  insertHiddenInput (value) {
    const valueInput = this.valueInputTarget.cloneNode()
    valueInput.value = value
    valueInput.disabled = false
    this.valueInputTarget.after(valueInput)
  }

  removeHiddenInput (value) {
    const valueInput = this.valueInputTargets.find((el) => el.value === value)
    valueInput?.remove()
  }

  performSearchQuery () {
    this.currentRequest?.abort()

    const { query } = this

    return this.performQuery({ query }).success((responseText) => {
      $(this.availableContainerTarget).html(responseText)
    })
  }

  performPageQuery () {
    const { query } = this
    const { page } = this.paginationMarkTarget.dataset

    return this.performQuery({ query, page }).success((responseText) => {
      $(this.paginationMarkTarget).replaceWith(responseText)
    })
  }

  performQuery ({ query, page }) {
    return $.rails.ajax({
      url: this.urlValue,
      data: { query, page: page || 1 },
      dataType: 'html',
      beforeSend: (xhr) => {
        if (this.currentRequest) {
          return false
        }

        this.currentRequest = xhr
        this.element.classList.add(LOADING_CLASS)
      },
      complete: () => {
        this.currentRequest = null
        this.element.classList.remove(LOADING_CLASS)
      }
    })
  }
}
